import request from '../request'

//入库单列表
export const entry_bill_index_request = p => {
    return request({
        method:'GET',
        url:'entry_bill/index',
        params: p
    })
}

//添加入库单
export const entry_bill_add_request = d => {
    return request({
        method:'POST',
        url:'entry_bill/add',
        data: d
    })
}

//删除入库单
export const entry_bill_del_request = id => {
    return request({
        method:'DELETE',
        url:'entry_bill/del',
        data: {
            id: id
        }
    })
}

//修改入库单
export const entry_bill_edit_request = d => {
    return request({
        method:'PUT',
        url:'entry_bill/edit',
        data: d
    })
}

//获取入库单详情
export const entry_bill_details_request = id => {
    return request({
        method:'GET',
        url:'entry_bill/details',
        params: {
            id
        }
    })
}

// 提交入库单
export const entry_bill_to_approval_request = d => {
    return request({
        method:'POST',
        url:'entry_bill/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看入库单审批记录
export const entry_bill_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'entry_bill/sel_approval',
        params: {
            id
        }
    })
}

// 审批入库单
export const entry_bill_approval_request = d => {
    return request({
        method:'PUT',
        url:'entry_bill/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回入库单
export const entry_bill_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'entry_bill/withdraw',
        data: {
            id: d.id
        }
    })
}