<template>
    <div v-loading.fullscreen.lock="loading">
        <entry-bill-search-bar-component
            add_auth="新增入库单"
            :AllClient="fAllClient"
            @search="get_entry_bill_index"
            @addTeam="addTeam"
        ></entry-bill-search-bar-component>
        <common-table-component
            details_auth="入库单详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_entry_bill"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_entry_bill_index"
        ></common-page-component>
        <entry-bill-edit-component
            :id="fid"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :AllClient="fAllClient"
            :fAllClientStockRate="fAllClientStockRate"
            :entry_bill_details_data="fentry_bill_details_data"
            :fAllSatff="fAllSatff"
            :storeroom_in_enterprise="fstoreroom_in_enterprise"
            @exitEntryBillDialog="dialogExit"
            @search="get_entry_bill_index"
            @fClientStockRateChange="get_client_stock_rate"
            @show_edit="show_edit"
            @frefresh="frefresh"
            @details_row="details_entry_bill"
        ></entry-bill-edit-component>
    </div>
</template>

<script>
import { entry_bill_index_request,entry_bill_details_request } from '@/network/WareHouse/EntryBill.js'
import { client_stock_rate_list_request,client_list_request,staff_list_request } from '@/network/list.js'
import { enterprise_storeroom_request } from '@/network/enterprise.js'

import EntryBillSearchBarComponent from '@/components/WareHouse/EntryBill/EntryBillSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import EntryBillEditComponent from '@/components/WareHouse/EntryBill/EntryBillEditComponent'

export default {
    name: 'EntryBillView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                state: '',
                name: '',
                bill_num: '',
                client_id: '',
                product_name: '',
                entry_staff_id: '',
                entry_date: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'entry_date',
                    label: '入库日期',
                    minWidth: '120px',
                },
                {
                    prop: 'name',
                    label: '入库单号',
                    minWidth: '120px',
                },{
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },{
                    prop: 'client_name',
                    label: '客户名称',
                    minWidth: '280px',
                },{
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px',
                },{
                    prop: 'entry_staff_name',
                    label: '录单员',
                    minWidth: '100px',
                }
            ],
            fentry_bill_details_data: {},
            fAllClient: [],
            fAllClientStockRate: [],
            fAllSatff: [],
            fstoreroom_in_enterprise: [],
            edit_auth: false,
            del_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_entry_bill_index(param = {}) {
            this.loading = true
            this.cond.state = param.state ?? this.cond.state
            this.cond.name = param.name ?? this.cond.name
            this.cond.bill_num = param.bill_num ?? this.cond.bill_num
            this.cond.client_id = param.client_id ?? this.cond.client_id
            this.cond.product_name = param.product_name ?? this.cond.product_name
            this.cond.entry_staff_id = param.entry_staff_id ?? this.cond.entry_staff_id
            this.cond.entry_date = param.entry_date ?? this.cond.entry_date
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            entry_bill_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_entry_bill(id) {
            this.fid = id
            this.fdialogFormVisible = true
            entry_bill_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fentry_bill_details_data = s.result
                        //权限判断
                        this.is_auth(s.result)
                        client_stock_rate_list_request({client_id: s.result.client_id})
                            .then((s) => {
                                this.fAllClientStockRate = s.result
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_all_client() {
            client_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_client_stock_rate(e) {
            client_stock_rate_list_request({client_id: e})
                .then((s) => {
                    this.fAllClientStockRate = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllSatff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_enterprise_storeroom() {
            this.login_loading = true
            enterprise_storeroom_request()
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.fstoreroom_in_enterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        frefresh() {
            this.details_entry_bill(this.fid)
            this.get_entry_bill_index()
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false
            
            if(res.state === 0) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交入库单')
                    this.edit_auth = this.$_has('修改入库单')
                    this.del_auth = this.$_has('删除入库单')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回入库单')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批入库单')
                }
                this.sel_approval_auth = this.$_has('查看入库单审批记录')
            } else if(res.state === 2) {
                this.sel_approval_auth = this.$_has('查看入库单审批记录')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交入库单')
                    this.edit_auth = this.$_has('修改入库单')
                    this.del_auth = this.$_has('删除入库单')
                }
                this.sel_approval_auth = this.$_has('查看入库单审批记录')
            }
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_entry_bill_index()
        this.get_all_client()
        this.get_all_staff()
        this.get_enterprise_storeroom()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        EntryBillSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        EntryBillEditComponent,
    },
    watch: {},
}
</script>

<style lang='less'></style>