<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="入库单号" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="入库单号搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="提单号" prop="bill_num">
                <el-input
                    v-model="form.bill_num"
                    placeholder="提单号"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="审批状态" prop="state">
                <el-select
                    v-model="form.state"
                    placeholder="选择状态"
                    class="clientc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(value,index) in $store.state.state"
                        :key="index"
                        :label="value"
                        :value="index"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="关联客户" prop="client_id">
                <el-select
                    v-model="form.client_id"
                    filterable
                    placeholder="选择客户"
                    class="clientc"
                    clearable
                >
                    <el-option
                        v-for="client in AllClient"
                        :key="client.id"
                        :label="client.name"
                        :value="client.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产品名称" prop="product_name">
                <el-input
                    v-model="form.product_name"
                    placeholder="产品名称搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="录单员" prop="entry_staff_id">
                <el-input
                    v-model="form.entry_staff_id"
                    placeholder="录单员搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="入库日期" prop="entry_date">
                <el-date-picker
                    v-model="form.entry_date"
                    align="right"
                    type="date"
                    placeholder="选择入库日期"
                    value-format="yyyy-MM-dd"
                    class="entry_datec"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加新入库单</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'EntryBillSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                state: '',
                name: '',
                bill_num: '',
                client_id: '',
                product_name: '',
                entry_staff_id: '',
                entry_date: '',
                page: 1,
            },
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
    },
    filters: {},
    props: {
        AllClient: {
            type: Array,
            default() {
                return []
            },
        },
        add_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>