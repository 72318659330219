<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="80%"
            :center="true"
            top="3vh"
            :close-on-click-modal="false"
            :before-close="() => closeEntryBillDialog('EntryBillEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="EntryBill.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="EntryBill.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="EntryBill.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="EntryBill.state===3" type="danger" size="mini">已驳回</el-tag>
            </template>
            <el-form
                :model="EntryBill"
                :rules="rules"
                ref="EntryBillEditForm"
                id="EntryBillEditFormId"
                label-width="100px"
                size="small"
                label-position="top"
            >
                <div v-if="show_type">
                    <fieldset class="fieldsetc">
                        <legend class="legendc">基础信息</legend>
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item label="客户" prop="client_id">
                                    <el-select
                                        v-model="EntryBill.client_id"
                                        filterable
                                        placeholder="选择客户"
                                        @change="(e) => ClientStockRateChange(e)"
                                        class="clientc"
                                        clearable
                                        size="small"
                                    >
                                        <el-option
                                            v-for="client in AllClient"
                                            :key="client.id"
                                            :label="client.name"
                                            :value="client.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item label="提单号" prop="bill_num">
                                    <el-input v-model="EntryBill.bill_num" size="small" clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item label="集装箱号">
                                    <el-input v-model="EntryBill.box_num" size="small" clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item label="其他信息">
                                    <el-input v-model="EntryBill.client_order" size="small" clearable></el-input>
                                </el-form-item>
                            </el-col >
                            <el-col :span="4">
                                <el-form-item label="司机手机" prop="driver_phone">
                                    <el-input v-model="EntryBill.driver_phone" size="small" clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </fieldset>
                    <fieldset  class="fieldsetc">
                        <legend class="legendc">产品信息</legend>
                        <div v-for="(value,index) in EntryBill.product" :key="index">
                            <fieldset>
                                <legend class="product_title">产品{{index + 1}}</legend>
                                <el-row :gutter="20">
                                    <el-col :span="6">
                                        <el-form-item
                                        label="产品名称"
                                        :prop="'product.' + index + '.product_id'"
                                        :rules="{ 
                                            required: true,
                                            message: '产品名称不能为空',
                                            trigger: 'blur' 
                                        }">
                                            <el-select
                                                v-model="value.product_id"
                                                filterable
                                                clearable
                                                placeholder="请选择"
                                                class="product_idc"
                                            >
                                                <el-option
                                                    v-for="itemname in product_list"
                                                    :key="itemname.id"
                                                    :label="itemname.name"
                                                    :value="itemname.id"
                                                >
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                        label="产品型号"
                                        :prop="'product.' + index + '.code'"
                                        >
                                            <el-input v-model="value.code"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                        label="订单号"
                                        :prop="'product.' + index + '.batch_number'"
                                        >
                                            <el-input v-model="value.batch_number"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                        label="规格"
                                        :prop="'product.' + index + '.specifications'"
                                        >
                                            <el-input v-model="value.specifications"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="6">
                                        <el-form-item
                                        label="产品应收总数量"
                                        :prop="'product.' + index + '.should_num'"
                                        :rules="[{ 
                                            required: true,
                                            message: '产品应收总数量不能为空',
                                            trigger: 'blur' 
                                        },{
                                            type: 'number',
                                            message: '产品应收总数量必须为数字',
                                            trigger: 'blur'
                                        }]">
                                            <el-input v-model.number="value.should_num"><i slot="suffix">件</i></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                        label="产品应收总重量"
                                        :prop="'product.' + index + '.should_weight'"
                                        :rules="[{ 
                                            required: true,
                                            message: '产品应收总重量不能为空',
                                            trigger: 'blur' 
                                        },{
                                            type: 'number',
                                            message: '产品应收总重量必须为数字',
                                            trigger: 'blur'
                                        }]">
                                            <el-input v-model.number="value.should_weight"><i slot="suffix">KG</i></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                        label="产品实收总数量"
                                        :prop="'product.' + index + '.reality_num'"
                                        :rules="[{ 
                                            required: true,
                                            message: '产品实收总数量不能为空',
                                            trigger: 'blur' 
                                        },{
                                            type: 'number',
                                            message: '产品实收总数量必须为数字',
                                            trigger: 'blur'
                                        }]">
                                            <el-input v-model.number="value.reality_num"><i slot="suffix">件</i></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                        label="产品实收总重量"
                                        :prop="'product.' + index + '.reality_weight'"
                                        :rules="[{ 
                                            required: true,
                                            message: '产品实收总重量不能为空',
                                            trigger: 'blur' 
                                        },{
                                            type: 'number',
                                            message: '产品实收总重量必须为数字',
                                            trigger: 'blur'
                                        }]">
                                            <el-input v-model.number="value.reality_weight" @input="(e) => count_labour_money(e,index,value)"><i slot="suffix">KG</i></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="6">
                                        <el-form-item
                                        label="总托盘数"
                                        :prop="'product.' + index + '.tray_num'"
                                        :rules="[{ 
                                            required: true,
                                            message: '托盘数不能为空',
                                            trigger: 'blur' 
                                        },{
                                            type: 'number',
                                            message: '托盘数必须为数字',
                                            trigger: 'blur'
                                        }]">
                                            <el-input v-model.number="value.tray_num" @input="(e) => count_labour_money(e,index,value)"><i slot="suffix">个</i></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                            label="仓储费率"
                                            :prop="'product.' + index + '.storage_rate'"
                                            :rules="[{ 
                                                required: true,
                                                message: '仓储费率不能为空',
                                                trigger: 'blur' 
                                            }]">
                                            <el-select
                                                v-model="value.storage_rate"
                                                placeholder="选择仓储费率"
                                                clearable
                                                size="small"
                                                class="clientc"
                                            >
                                                <el-option
                                                    v-for="ClientStockRate in AllClientStockRate"
                                                    :key="ClientStockRate.id"
                                                    :label="ClientStockRate | filter_rate"
                                                    :value="ClientStockRate.id"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                            class="yingshou"
                                            label="应收其他费用"
                                            :prop="'product.' + index + '.other_money'"
                                            :rules="[{ 
                                                required: true,
                                                message: '应收其他费用不能为空',
                                                trigger: 'blur' 
                                            }]">
                                            <el-input v-model="value.other_money" size="small" clearable><i slot="suffix">元</i></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                            class="yingfu"
                                            label="应付其它费用"
                                            :prop="'product.' + index + '.pay_money'"
                                            :rules="[{ 
                                                required: true,
                                                message: '应付其它费用不能为空',
                                                trigger: 'blur' 
                                            }]">
                                            <el-input v-model="value.pay_money" size="small" clearable><i slot="suffix">元</i></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row  :gutter="20">
                                    <el-col :span="6">
                                        <el-form-item
                                            class="yingshou"
                                            label="应收卸货费率"
                                            :prop="'product.' + index + '.labour_money_rate'"
                                            :rules="[{ 
                                                required: true,
                                                message: '应收卸货费率不能为空',
                                                trigger: 'blur' 
                                            }]">
                                            <el-select
                                                v-model="value.labour_money_rate"
                                                placeholder="选择应收卸货费率"
                                                @change="(e) => count_labour_money(e,index,value)"
                                                clearable
                                                size="small"
                                                class="clientc"
                                            >
                                                <el-option
                                                    v-for="ClientStockRate in AllClientStockRate2"
                                                    :key="ClientStockRate.id"
                                                    :label="ClientStockRate | filter_rate2"
                                                    :value="ClientStockRate.id"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                            class="yingshou"
                                            label="应收卸货费"
                                            :prop="'product.' + index + '.labour_money'"
                                            :rules="[{ 
                                                required: true,
                                                message: '应收卸货费不能为空',
                                                trigger: 'blur' 
                                            }]">
                                            <el-input v-model="value.labour_money" size="small" readonly><i slot="suffix">元</i></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                            class="yingfu"
                                            label="应付卸货费率"
                                            :prop="'product.' + index + '.pay_labour_money_rate'"
                                            :rules="[{ 
                                                required: true,
                                                message: '应付卸货费率不能为空',
                                                trigger: 'blur' 
                                            }]">
                                            <el-select
                                                v-model="value.pay_labour_money_rate"
                                                placeholder="选择应付卸货费率"
                                                @change="(e) => count_labour_money(e,index,value)"
                                                clearable
                                                size="small"
                                                class="clientc"
                                            >
                                                <el-option
                                                    v-for="ClientStockRate in AllClientStockRate3"
                                                    :key="ClientStockRate.id"
                                                    :label="ClientStockRate | filter_rate2"
                                                    :value="ClientStockRate.id"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                            class='yingfu'
                                            label="应付卸货费"
                                            :prop="'product.' + index + '.pay_labour_money'"
                                            :rules="[{ 
                                                required: true,
                                                message: '应收卸货费不能为空',
                                                trigger: 'blur' 
                                            }]">
                                            <el-input v-model="value.pay_labour_money" size="small" readonly><i slot="suffix">元</i></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="24">
                                        <el-form-item label="备注" prop="note">
                                            <el-input type="textarea" :rows="3" v-model="value.note"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <div>
                                    <fieldset>
                                        <legend class="legendc">库位信息</legend>
                                        <div v-for="(location_v,location_i) in value.location" :key="location_i">
                                            <fieldset>
                                                <legend class="location_title">库位{{location_i + 1}}</legend>
                                                    <el-row :gutter="20">
                                                        <el-col :span="8">
                                                            <el-form-item
                                                            label="仓库"
                                                            :prop="'product.' + index + '.location.' + location_i + '.storeroom'"
                                                            :rules="{ 
                                                                required: true,
                                                                message: '仓库不能为空',
                                                                trigger: 'blur' 
                                                            }">
                                                                <el-cascader
                                                                    v-model="location_v.storeroom"
                                                                    :options="storeroom_in_enterprise"
                                                                    :props="{ expandTrigger: 'hover' }"
                                                                    clearable
                                                                    style="width: 100%"
                                                                    @change="(e) => StoreroomChange(e,location_v)"
                                                                >
                                                                </el-cascader>
                                                            </el-form-item>
                                                        </el-col>
                                                        <el-col :span="4">
                                                            <el-form-item label="库位"
                                                            :prop="'product.' + index + '.location.' + location_i + '.location_id'"
                                                            :rules="{ 
                                                                required: true,
                                                                message: '库位不能为空',
                                                                trigger: 'blur' 
                                                            }">
                                                                <el-select
                                                                    v-model="location_v.location_id"
                                                                    filterable
                                                                    clearable
                                                                    placeholder="请选择"
                                                                    class="sitec"
                                                                >
                                                                    <el-option
                                                                        v-for="item in location_v.site_in_storeroom"
                                                                        :key="item.id"
                                                                        :label="item.name"
                                                                        :value="item.id"
                                                                    >
                                                                    </el-option>
                                                                </el-select>
                                                            </el-form-item>
                                                        </el-col>
                                                        <el-col :span="4">
                                                            <el-form-item
                                                            label="存放重量"
                                                            :prop="'product.' + index + '.location.' + location_i + '.product_weight'"
                                                            :rules="{ 
                                                                required: true,
                                                                message: '存放重量不能为空',
                                                                trigger: 'blur' 
                                                            }">
                                                                <el-input v-model="location_v.product_weight"><i slot="suffix">KG</i></el-input>
                                                            </el-form-item>
                                                        </el-col>
                                                        <el-col :span="4">
                                                            <el-form-item
                                                            label="存放托盘数"
                                                            :prop="'product.' + index + '.location.' + location_i + '.product_tray_num'"
                                                            :rules="{ 
                                                                required: true,
                                                                message: '存放托盘数不能为空',
                                                                trigger: 'blur' 
                                                            }">
                                                                <el-input v-model="location_v.product_tray_num"><i slot="suffix">个</i></el-input>
                                                            </el-form-item>
                                                        </el-col>
                                                        <el-col :span="4">
                                                            <el-form-item label="　">
                                                                <el-button type="success" plain icon="el-icon-plus" circle size="small" v-if="location_i === value.location.length -1" @click="AddLocation(value.location)"></el-button>
                                                                <el-button type="danger" plain icon="el-icon-delete" circle size="small" v-if="location_i !==0" @click.prevent="DelLocation(value.location,location_v)"></el-button>
                                                            </el-form-item>
                                                        </el-col>
                                                    </el-row>
                                            </fieldset>
                                        </div>
                                    </fieldset>
                                </div>
                                <div>
                                    <el-row style="text-align: center;">
                                        <el-button type="success" plain  circle size="small" v-if="index === EntryBill.product.length -1" @click="EntryBillAddProduct('EntryBillEditForm')">增加产品</el-button>
                                        <el-button type="danger" plain  circle size="small" v-if="index !==0" @click.prevent="EntryBillDelProduct(value)">删除产品</el-button>
                                    </el-row>
                                </div>
                            </fieldset>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend class="legendc">其他信息</legend>
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item label="仓管员" prop="warehouse_staff_id">
                                    <el-select
                                        v-model="EntryBill.warehouse_staff_id"
                                        filterable
                                        placeholder="选择仓管员"
                                        clearable
                                        size="small"
                                        class="clientc"
                                    >
                                        <el-option
                                            v-for="staff in fAllSatff"
                                            :key="staff.id"
                                            :label="staff.name"
                                            :value="staff.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="录单员" prop="entry_staff_id">
                                    <el-select
                                        v-model="EntryBill.entry_staff_id"
                                        filterable
                                        placeholder="选择录单员"
                                        clearable
                                        size="small"
                                        class="clientc"
                                    >
                                        <el-option
                                            v-for="staff in fAllSatff"
                                            :key="staff.id"
                                            :label="staff.name"
                                            :value="staff.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="入库日期" prop="entry_date">
                                    <el-date-picker
                                    v-model="EntryBill.entry_date"
                                        align="right"
                                        type="date"
                                        placeholder="选择日期"
                                        value-format="yyyy-MM-dd"
                                        class="entry_date"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </fieldset>
                </div>
                <div v-else>
                    <fieldset class="fieldsetc">
                        <legend class="legendc">基础信息</legend>
                        <el-descriptions
                            class="margin-top"
                            :column="4"
                            :labelStyle="{'text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            direction="vertical"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>客户名称</span>
                                </template>
                                <span>{{EntryBill.client_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>提单号</span>
                                </template>
                                <span>{{EntryBill.bill_num}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>集装箱号</span>
                                </template>
                                <span>{{EntryBill.box_num}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>其他信息</span>
                                </template>
                                <span>{{EntryBill.client_order}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </fieldset>
                    <fieldset  class="fieldsetc">
                        <legend class="legendc">产品信息</legend>
                        <div v-for="(value,index) in EntryBill.product" :key="index">
                            <fieldset>
                                <legend class="product_title">产品{{index + 1}}</legend>
                                <el-descriptions
                                    class="margin-top"
                                    :column="4"
                                    :labelStyle="{'text-align':'center'}"
                                    :contentStyle="{'text-align': 'center'}"
                                    direction="vertical"
                                    border
                                >
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>产品名称</span>
                                        </template>
                                        <span>{{value.product_name}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>产品型号</span>
                                        </template>
                                        <span>{{value.code}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>订单号</span>
                                        </template>
                                        <span>{{value.batch_number}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>规格</span>
                                        </template>
                                        <span>{{value.specifications}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>产品应收总数量</span>
                                        </template>
                                        <span>{{value.should_num ? value.should_num + '件' : ''}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>产品应收总重量</span>
                                        </template>
                                        <span>{{value.should_weight | diy_weight}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>产品实收总数量</span>
                                        </template>
                                        <span>{{value.reality_num ? value.reality_num + '件' : ''}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>产品实收总重量</span>
                                        </template>
                                        <span>{{value.reality_weight | diy_weight}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>总托盘数</span>
                                        </template>
                                        <span>{{value.tray_num ? value.tray_num + '个' : ''}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>仓储费率</span>
                                        </template>
                                        <span>{{value.storage_rate_name ? value.storage_rate_name : ''}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                        <template slot="label">
                                            <span>应收其他费用</span>
                                        </template>
                                        <span>{{value.other_money | diy}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                        <template slot="label">
                                            <span>应付其它费用</span>
                                        </template>
                                        <span>{{value.pay_money | diy}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                        <template slot="label">
                                            <span>应收卸货费率</span>
                                        </template>
                                        <span>{{value.labour_money_rate_name ? value.labour_money_rate_name : ''}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                        <template slot="label">
                                            <span>应收卸货费</span>
                                        </template>
                                        <span>{{value.labour_money | diy}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                        <template slot="label">
                                            <span>应付卸货费率</span>
                                        </template>
                                        <span>{{value.pay_labour_money_rate_name ? value.pay_labour_money_rate_name : ''}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                        <template slot="label">
                                            <span>应付卸货费</span>
                                        </template>
                                        <span>{{value.pay_labour_money | diy}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span="4">
                                        <template slot="label">
                                            <span>备注</span>
                                        </template>
                                        <span>{{value.note}}</span>
                                    </el-descriptions-item>
                                </el-descriptions>
                                <div>
                                    <fieldset>
                                        <legend class="legendc">库位信息</legend>
                                        <el-table
                                            :data="value.location"
                                            border
                                            highlight-current-row
                                            style="width: 100%"
                                        >
                                            <el-table-column align="center" type="index"> </el-table-column>
                                            <el-table-column
                                                align="center"
                                                label="公司"
                                            >
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row['enterprise_name'] }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                label="仓库"
                                            >
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row['storeroom_name'] }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                label="库位"
                                            >
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row['location_name'] }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                label="存放重量"
                                            >
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row['product_weight'] |  diy_weight }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                label="存放托盘数"
                                            >
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row['product_tray_num'] }}</span>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </fieldset>
                                </div>
                            </fieldset>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend class="legendc">其他信息</legend>
                        <el-descriptions
                            class="margin-top"
                            :column="3"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>仓管员</span>
                                </template>
                                <span>{{EntryBill.warehouse_staff_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>录单员</span>
                                </template>
                                <span>{{EntryBill.entry_staff_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>入库日期</span>
                                </template>
                                <span>{{EntryBill.entry_date}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </fieldset>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <!-- <el-button
                        plain
                        circle
                        icon="el-icon-printer"
                        @click="printBtn('EntryBillEditForm')"
                        >打印</el-button
                    >
                    <el-button
                        plain
                        circle
                        icon="el-icon-printer"
                        @click="outPDF('EntryBillEditFormId')"
                        >导出</el-button
                    > -->
                    <el-button
                        v-if="to_approval_auth"
                        type="success"
                        plain
                        @click="to_approval('EntryBillEditForm')"
                        >提交</el-button
                    >
                    <el-button
                        v-if="sel_approval_auth"
                        type="warning"
                        plain
                        @click="sel_approval()"
                        >查看审批</el-button
                    >
                    <el-button
                        v-if="withdraw_auth"
                        type="danger"
                        plain
                        @click="EntryBillWithdraw('EntryBillEditForm')"
                        >审批撤回</el-button
                    >
                    <el-button
                        v-if="edit_auth"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="del_auth"
                        type="danger"
                        plain
                        @click="EntryBillDel('EntryBillEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeEntryBillDialog('EntryBillEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('EntryBillEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('EntryBillEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeEntryBillDialog('EntryBillEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('EntryBillEditForm')"
                        >确定修改</el-button
                    >
                    <el-button plain @click="closeEntryBillDialog('EntryBillEditForm')"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="EntryBill.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="EntryBillApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'

import {
    entry_bill_add_request,
    entry_bill_del_request,
    entry_bill_edit_request,
    entry_bill_to_approval_request,
    entry_bill_sel_approval_request,
    entry_bill_approval_request,
    entry_bill_withdraw_request
} from '@/network/WareHouse/EntryBill.js'
import { location_list_request,product_list_request } from '@/network/list.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name: 'EntryBillEditComponent',
    data() {
        return {
            login_loading: false,
            EntryBill: {
                client_id: '',
                bill_num: '',
                box_num: '',
                client_order: '',
                driver_phone: '',
                product: [
                    {
                        product_id: '',
                        code: '',
                        batch_number: '',
                        specifications: '',
                        should_num: '',
                        should_weight: '',
                        reality_num: '',
                        reality_weight: '',
                        tray_num: '',
                        storage_rate: '',
                        labour_money_rate: '',
                        labour_money: '',
                        pay_labour_money_rate: '',
                        pay_labour_money: '',
                        other_money: 0,
                        pay_money: 0,
                        note: '',
                        location: [
                            {
                                storeroom: '',
                                location_id: '',
                                product_weight: '',
                                product_tray_num: '',
                                site_in_storeroom: []
                            }
                        ]
                    }
                ],
                warehouse_staff_id: '',
                entry_staff_id: this.$store.state.user.id,
                entry_date: '',
            },
            rules: {
                client_id: [
                    {
                        required: true,
                        message: '请选客户',
                        trigger: 'blur',
                    },
                ],
                driver_phone: [
                    {
                        pattern: /^1[3,5,6,7,8,9]\d{9}$/,
                        message: '手机号格式不正确',
                        trigger: 'blur',
                    },
                ],
                storage_rate: [
                    {
                        required: true,
                        message: ' 请选择仓储费率',
                        trigger: 'blur',
                    },
                ],
                warehouse_staff_id: [
                    {
                        required: true,
                        message: ' 请选择仓管员',
                        trigger: 'blur',
                    },
                ],
                entry_staff_id: [
                    {
                        required: true,
                        message: ' 请选择录单员',
                        trigger: 'blur',
                    },
                ],
                entry_date: [
                    {
                        required: true,
                        message: ' 请选择入库日期',
                        trigger: 'blur',
                    },
                ]
            },
            AllClientStockRate: [], //仓储费率
            AllClientStockRate2: [],    //应收装卸费率
            AllClientStockRate3: [],    //应付装卸费率
            localtion_in_storeroom: [],
            product_list: [],
            ApprovalData: [],
            ApprovalDialogVisible: false,
            EntryBillApprovalRequest:entry_bill_approval_request
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.EntryBill.name ? '入库单：' + this.EntryBill.name : '入库单详情'
            } else if (this.show_type === 1) {
                return '添加入库单'
            } else {
                return '修改入库单'
            }
        }
    },
    methods: {
        closeEntryBillDialog() {
            this.reset_data()
            this.$emit('exitEntryBillDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.EntryBillInsert(formName)
            })
        },
        EntryBillInsert(formName) {
            let err = false
            this.EntryBill.product = this.EntryBill.product.map( v => {

                if(v.reality_num < 0) {
                    this.$message.error('实收数量不得小于0')
                    err = true 
                }

                if(v.reality_weight < 0) {
                    this.$message.error('实收重量不得小于0')
                    err = true 
                }

                if(v.labour_money < 0 ) {
                    this.$message.error('产品对应应收卸货费不能小于0')
                    err = true 
                }

                v.location = v.location.map( vv => {
                    delete vv.site_in_storeroom
                    return vv
                })

                const count_location_weight = v.location.reduce( (s,cur) => {
                    return s + cur.product_weight * 1
                },0)
                if(count_location_weight !== (v.reality_weight * 1)) {
                    this.$message.error('分配到库位的产品总重量与入库单产品总重量不等')
                    err = true
                }

                return v
            })

            if(err) {
                return
            }

            this.login_loading = true
            entry_bill_add_request(this.EntryBill)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitEntryBillDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm() {
            this.reset_data()
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.EntryBillEdit(formName)
            })
        },
        EntryBillEdit(formName) {

            let err = false
            this.EntryBill.product = this.EntryBill.product.map( v => {

                if(v.reality_num < 0) {
                    this.$message.error('实收数量不得小于0')
                    err = true 
                }

                if(v.reality_weight < 0) {
                    this.$message.error('实收重量不得小于0')
                    err = true 
                }

                if(v.labour_money < 0 ) {
                    this.$message.error('产品对应应收卸货费不能小于0')
                    err = true 
                }

                v.location = v.location.map( vv => {
                    delete vv.site_in_storeroom
                    return vv
                })

                const count_location_weight = v.location.reduce( (s,cur) => {
                    return s + cur.product_weight * 1
                },0)
                if(count_location_weight !== (v.reality_weight * 1)) {
                    this.$message.error('分配到库位的产品总重量与入库单产品总重量不等')
                    err = true
                }

                return v
            })

            if(err) {
                return
            }

            this.login_loading = true
            this.EntryBill.id = this.id
            entry_bill_edit_request(this.EntryBill)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitEntryBillDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        EntryBillDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    entry_bill_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeEntryBillDialog(formName))
                })
                .catch((err) => {})
        },
        ClientStockRateChange(e) {
            this.EntryBill.storage_rate = ''
            this.EntryBill.labour_money_rate = ''
            this.EntryBill.product = [
                    {
                        product_id: '',
                        code: '',
                        batch_number: '',
                        specifications: '',
                        should_num: '',
                        should_weight: '',
                        reality_num: '',
                        reality_weight: '',
                        tray_num: '',
                        storage_rate: '',
                        labour_money_rate: '',
                        labour_money: '',
                        pay_labour_money_rate: '',
                        pay_labour_money: '',
                        other_money: 0,
                        pay_money: 0,
                        note: '',
                        location: [
                            {
                                storeroom: '',
                                location_id: '',
                                product_weight: '',
                                product_tray_num: '',
                                site_in_storeroom: []
                            }
                        ]
                    }
            ]
            if(e === '') {
                this.product_list = []
                this.AllClientStockRate = []
                this.AllClientStockRate2 = []
                this.AllClientStockRate3 = []
            } else {
                this.get_product_list({with_client_id: e})
                this.$emit('fClientStockRateChange', e)
            }
        },
        EntryBillAddProduct() {
            const storage_rate = this.AllClientStockRate.length>0 ? this.AllClientStockRate[0].id : ''
            const labour_money_rate = this.AllClientStockRate2.length>0 ? this.AllClientStockRate2[0].id : ''
            const pay_labour_money_rate = this.AllClientStockRate3.length>0 ? this.AllClientStockRate3[0].id : ''
            this.EntryBill.product.push({
                        product_id: '',
                        code: '',
                        batch_number: '',
                        specifications: '',
                        should_num: '',
                        should_weight: '',
                        reality_num: '',
                        reality_weight: '',
                        tray_num: '',
                        storage_rate: storage_rate,
                        labour_money_rate: labour_money_rate,
                        labour_money: '',
                        pay_labour_money_rate: pay_labour_money_rate,
                        pay_labour_money: '',
                        other_money: 0,
                        pay_money: 0,
                        note: '',
                        location: [
                            {
                                storeroom: '',
                                location_id: '',
                                product_weight: '',
                                product_tray_num: '',
                                site_in_storeroom: []
                            }
                        ],
                        key: Date.now()
                    })
        },
        EntryBillDelProduct(item) {
            const index = this.EntryBill.product.indexOf(item)
            if (index !== -1) {
                this.EntryBill.product.splice(index, 1)
            }
        },
        AddLocation(arr) {
            arr.push({
                storeroom: '',
                location_id: '',
                product_weight: '',
                product_tray_num: '',
                site_in_storeroom: [],
                key: Date.now()
            })
        },
        DelLocation(item,v) {
            const index = item.indexOf(v)
            if (index !== -1) {
                item.splice(index, 1)
            }
        },
        StoreroomChange(e,location_arr) {
            const storeroom_id  = e[e.length - 1]
            location_arr.location_id = ''
            location_arr.site_in_storeroom = this.localtion_in_storeroom.filter( v => v.storeroom_id === storeroom_id)
        },
        reset_data() {
            this.EntryBill = {
                client_id: '',
                bill_num: '',
                box_num: '',
                client_order: '',
                driver_phone: '',
                product: [
                    {
                        product_id: '',
                        code: '',
                        batch_number: '',
                        specifications: '',
                        should_num: '',
                        should_weight: '',
                        reality_num: '',
                        reality_weight: '',
                        tray_num: '',
                        storage_rate: '',
                        labour_money_rate: '',
                        labour_money: '',
                        pay_labour_money_rate: '',
                        pay_labour_money: '',
                        other_money: 0,
                        pay_money: 0,
                        note: '',
                        location: [
                            {
                                storeroom: '',
                                location_id: '',
                                product_weight: '',
                                product_tray_num: '',
                                site_in_storeroom: []
                            }
                        ]
                    }
                ],
                storage_rate: '',
                labour_money_rate: '',
                labour_money: '',
                other_money: '',
                pay_money: '',
                warehouse_staff_id: '',
                entry_staff_id: this.$store.state.user.id,
                entry_date: '',
            }
        },
        get_localtion_in_storeroom() {
            location_list_request()
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.localtion_in_storeroom = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        get_product_list(param = {}) {
            product_list_request(param)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.product_list = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        count_labour_money(e,index,value) {
            let rate = 0
            let pay_rate = 0
            let weight = 0
            let tray_num = 0
            let rate_type = 2
            if(value.labour_money_rate !== '')
            {
                this.fAllClientStockRate.forEach(v => {
                    if(v.id === value.labour_money_rate) {
                        rate = v.rate
                        rate_type = v.type
                    }
                });
            }
            if(value.pay_labour_money_rate !== '')
            {
                this.fAllClientStockRate.forEach(v => {
                    if(v.id === value.pay_labour_money_rate) {
                        pay_rate = v.rate
                    }
                });
            }
            if(value.reality_weight !== '') {
                weight = value.reality_weight
            }
            if(value.tray_num !== '') {
                tray_num = value.tray_num
            }

            //计算装卸费
            if(rate_type === 2) {
                this.EntryBill.product[index].labour_money = rate * weight / 1000
            } else if(rate_type === 3) {
                this.EntryBill.product[index].labour_money = rate * tray_num
            }

            this.EntryBill.product[index].pay_labour_money = pay_rate * weight / 1000
        },
        printBtn(formRef) {
            this.$print(this.$refs[formRef])
        },
        outPDF(formId) {
            const params = {
                name: this.EntryBill.name,
                code: this.$store.state.user.name,
                title: '20210714'
            }
            const html = document.getElementById(formId)
            this.getPdf(html, params)
        },
        to_approval(formName) {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    entry_bill_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeEntryBillDialog(formName))
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            entry_bill_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        EntryBillWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    entry_bill_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        }
    },
    filters: {
        filter_rate: v=>{
            if(v.type === 0) {
                return v.rate + '元/吨/天'
            }
            if(v.type === 5) {
                return v.rate + '元/托/天 '
            }
        },
        filter_rate2: v=>{
            if(v.type === 2) {
                return v.rate + '元/吨/次'
            }
            if(v.type === 3) {
                return v.rate + '元/托/次'
            }
            if(v.type === 4) {
                return v.rate + '元/吨/次'
            }
        },
        diy: v=>{
            return v ? fomatFloat(v) + '元' : '0.00元'
        },
        diy_weight(v) {
            return v ? thousandBitSeparator(v) + ' KG' : ''
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        entry_bill_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        AllClient: {
            type: Array,
            default() {
                return []
            },
        },
        fAllClientStockRate: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        fAllSatff: Array,
        storeroom_in_enterprise: Array,
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        }
    },
    created() {
        this.get_localtion_in_storeroom()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonApprovalDialog
    },
    watch: {
        entry_bill_details_data(newVal) {
            this.EntryBill = newVal
            this.get_product_list({with_client_id: newVal.client_id})
        },
        fAllClientStockRate(newVal) {
            this.AllClientStockRate = newVal.filter( v => { //仓储费率
                return v.type === 0 || v.type === 5
            })
            this.AllClientStockRate2 = newVal.filter( v => {    //应收装卸费率
                return v.type === 2 || v.type === 3
            })
            this.AllClientStockRate3 = newVal.filter( v => v.type === 4) //应付装卸费率

            this.EntryBill.product.forEach(v => {
                v.storage_rate = this.AllClientStockRate.length>0 ? this.AllClientStockRate[0].id : ''
                v.labour_money_rate = this.AllClientStockRate2.length>0 ? this.AllClientStockRate2[0].id : ''
                v.pay_labour_money_rate = this.AllClientStockRate3.length>0 ? this.AllClientStockRate3[0].id : ''
            });
        },
    },
}
</script>

<style lang='less'>
.mytitie {
    text-align: center;
}
.clientc {
    width: 100%;
}
.product_idc {
    width: 100%;
}
.entry_date.el-date-editor.el-input {
    width: 100%;
}
.fieldsetc {
    margin-bottom: 20px;
}
.legendc {
    color: #921AFF;
}
.details>.el-form-item__label {
    color:rgb(66, 125, 235);
}
.yingshou>.el-form-item__label {
    color:hsl(100, 36%, 48%);
}
.yingfu>.el-form-item__label {
    color:red;
}
.storage_ratec {
    width: 100%;
}
.product_title {
    margin-top: 10px;
    color: rgb(255, 70, 255);
}
.location_title {
    color: rgb(15, 248, 209);
}
/* 去除页眉页脚 */
@page {
  size: auto A4 landscape;
  margin: 3mm;
}
/* 增加padding */
#print {
  background: #fff;
  box-sizing: border-box;
  padding: 2rem 3rem 4rem;
}
</style>